import React from "react";
import PropTypes from "prop-types";
import Accordion from "react-bootstrap/Accordion";
import Image from "@ui/image";
import { Col, Row } from "@ui/wrapper";
import { ImageType } from "@utils/types";
import { StyledAccordion } from "./style";

const AccordionWrap = ({
    defaultActiveKey,
    data,
    layout,
    disableDefaultActive,
    ...props
}) => {
    return (
        <StyledAccordion layout={layout} {...props}>
            <Accordion
                defaultActiveKey={data[0].id || defaultActiveKey}
            >
                <Row>
                    <Col lg={6}>
                        {data?.map((item) => (
                            <Accordion.Item key={item.id} eventKey={item.id}>
                                <Accordion.Header>{item.title}</Accordion.Header>
                                <Accordion.Body dangerouslySetInnerHTML={{__html: item.description}} />
                            </Accordion.Item>
                        ))}
                    </Col>
                    <Col lg={6} mt={["20px", null, null, 0]}>
                        {data?.map((item) => (
                            <Accordion.Item key={item.id} eventKey={item.id}>
                                <Accordion.Body className="case-study-image-wrapper">
                                    <Image
                                        src={item.images?.[0]?.src}
                                        alt={item.images?.[0]?.alt}
                                        className="case-study-image"
                                    />
                                </Accordion.Body>
                            </Accordion.Item>
                        ))}
                    </Col>                   
                </Row>
            </Accordion>
        </StyledAccordion>
    );
};

AccordionWrap.propTypes = {
    layout: PropTypes.oneOf([1, 2]),
    defaultActiveKey: PropTypes.number,
    data: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            title: PropTypes.string,
            description: PropTypes.string,
            images: PropTypes.arrayOf(PropTypes.shape(ImageType)),
        })
    ).isRequired,
    disableDefaultActive: PropTypes.bool,
};

AccordionWrap.defaultProps = {
    layout: 1,
};

export default AccordionWrap;
