import React from "react";
import Typed from "react-typed";
import "react-typed/dist/animatedCursor.css";
import { Container } from "@ui/wrapper";
import { SectionWrap, TypedTextWrap } from "./style";

const TypedText = () => {
    return (
        <SectionWrap>
            <TypedTextWrap>
                <span className="not-typical">
                    We are a software development
                    <br />
                    team focused on <br />
                </span>
                <Typed
                    strings={[
                        "building solutions",
                        "great communication",
                        "code quality",
                        "sustainable technology",
                    ]}
                    typeSpeed={40}
                    backSpeed={50}
                    loop
                    className="black"
                />
            </TypedTextWrap>
        </SectionWrap>
    );
};

export default TypedText;
