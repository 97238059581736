import React from "react";
import PropTypes from "prop-types";
import { Container, Row, Col } from "@ui/wrapper";
import Heading from "@ui/heading";
import Text from "@ui/text";
import Image from "@ui/image";
import Button from "@ui/button";
import {
    SectionTitleType,
    ButtonType,
    TextType,
    HeadingType,
} from "@utils/types";

const TierArea = ({ data, align }) => {
    return (
        <Container className={`mb-2 ${align}`}>
            {data?.headings &&
                data.headings.map((heading) => (
                    <Heading mb={["20px"]} as={heading.level} className="">
                        {heading.content}
                    </Heading>
                ))}
            <Row>
                {data?.svg?.src?.publicURL && (
                    <Col
                        lg={{ span: 4, offset: 1 }}
                        mb={["10px", null, null, 0]}
                    >
                        <Image
                            src={data.svg.src.publicURL}
                            alt="Maintenance & Support Plans"
                        />
                    </Col>
                )}
                <Col
                    lg={data?.svg?.src?.publicURL && { span: 5, offset: 1 }}
                    className={!data?.svg?.src?.publicURL && "mx-auto"}
                >
                    {data?.texts &&
                        data.texts.map((bullet) => {
                            return (
                                <Text fontSize="18px" mb="20px">
                                    <i className={`far fa-check me-2`} />
                                    {bullet.content}
                                    {bullet.interior_bullets?.map((bullet) => (
                                        <li className="ms-5">
                                            {bullet.content}
                                        </li>
                                    ))}
                                </Text>
                            );
                        })}
                    {data?.buttons?.map(({ id, path, content, ...rest }) => (
                        <Button key={id} path={path} fontSize="15px" {...rest}>
                            {content}
                        </Button>
                    ))}
                </Col>
            </Row>
        </Container>
    );
};

TierArea.propTypes = {
    data: PropTypes.shape({
        section_title: PropTypes.shape(SectionTitleType),
        buttons: PropTypes.arrayOf(PropTypes.shape(ButtonType)),
        headings: PropTypes.arrayOf(PropTypes.shape(HeadingType)),
        texts: PropTypes.arrayOf(PropTypes.shape(TextType)),
    }),
    align: PropTypes.string,
};

export default TierArea;
