import React from "react";
import PropTypes from "prop-types";
import { Container, Row, Col } from "@ui/wrapper";
import Image from "@ui/image";
import Button from "@ui/button";
import TypedArea from "@containers/elements/typed-text";
import { HeadingType, ImageType } from "@utils/types";
import { HeroWrapper, StyledBG, HeroTextWrap } from "./style";

const HeroArea = ({ data }) => {
    return (
        <HeroWrapper>
            {data?.images[0]?.src && (
                <StyledBG>
                    <Image
                        src={data.images[0].src}
                        alt={data.images[0]?.alt || "Appointment"}
                    />
                </StyledBG>
            )}
            <Container>
                <Row>
                    <Col md={12}>
                        <HeroTextWrap>
                            <TypedArea />
                            {data?.buttons?.map(
                                ({ id, path, content, ...rest }) => (
                                    <Button key={id} path={path} {...rest}>
                                        {content}
                                    </Button>
                                )
                            )}
                        </HeroTextWrap>
                    </Col>
                </Row>
            </Container>
        </HeroWrapper>
    );
};

HeroArea.propTypes = {
    data: PropTypes.shape({
        headings: PropTypes.arrayOf(PropTypes.shape(HeadingType)),
        images: PropTypes.arrayOf(PropTypes.shape(ImageType)),
        show_form: PropTypes.bool,
    }),
};

export default HeroArea;
