import styled, { device } from "@styled";

export const PageHeaderWrap = styled.div`
    position: relative;
    isolation: isolate;
    padding-block-start: 60px;
    padding-block-end: 60px;
    ${device.medium} {
        padding-block-start: 100px;
        padding-block-end: 100px;
    }
    ${device.large} {
        padding-block-start: 192px;
        padding-block-end: 165px;
    }
`;

export const StyledBG = styled.div`
    position: absolute;
    inset: 0;
    z-index: -1;
    & > div {
        height: 100%;
        width: 100%;
    }
    .gatsby-image-wrapper {
        &:after {
            position: absolute;
            content: ".";
            display: block;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: linear-gradient(
                45deg,
                rgba(158, 181, 203, 1) 0%,
                rgba(255, 255, 255, 0.8) 40%,
                rgba(144, 144, 144, 0.8) 70%,
                rgba(19, 142, 160, 0.9) 100%
            );
        }
    }
`;

export const StyledTitle = styled.h1`
    color: #000;
    margin-block-end: 15px;
`;

export const StyledDesc = styled.h5`
    color: #000;
    font-weight: 400;
`;
