import styled, { device, themeGet } from "@styled";

export const SectionWrap = styled.section`
    padding-block-start: 30px;
    ${device.large} {
        margin-block-start: -90px;
    }
    .same-height-divs {
        flex-grow: 1;
        display: flex;
    }
`;
