import React from "react";
import PropTypes from "prop-types";
import { Container, Row, Col } from "@ui/wrapper";
import Text from "@ui/text";
import Image from "@ui/image";
import Button from "@ui/button";
import SectionTitle from "@ui/section-title";
import {
    SectionTitleType,
    ButtonType,
    TextType,
    HeadingType,
} from "@utils/types";

const AboutArea = ({ data, mt, titleClass }) => {
    return (
        <Container className={`mb-5 ${mt}`}>
            {data?.section_title && (
                <SectionTitle
                    mb={["30px"]}
                    title={data.section_title?.title}
                    subtitle={data.section_title?.subtitle}
                    className={titleClass}
                    layout={!data?.svg?.src?.publicURL && 3}
                />
            )}

            <Row>
                {data?.svg?.src?.publicURL && (
                    <Col
                        lg={{ span: 4, offset: 1 }}
                        mb={["10px", null, null, 0]}
                    >
                        <Image src={data.svg.src.publicURL} alt="Our Story" />
                    </Col>
                )}
                <Col
                    lg={data?.svg?.src?.publicURL && { span: 5, offset: 1 }}
                    className={!data?.svg?.src?.publicURL && "mx-auto"}
                >
                    {data?.texts?.[0] && (
                        <Text fontSize="18px" mb="20px">
                            {data.texts[0]?.content}
                        </Text>
                    )}

                    {data?.buttons?.map(({ id, path, content, ...rest }) => (
                        <Button key={id} path={path} fontSize="15px" {...rest}>
                            {content}
                        </Button>
                    ))}
                </Col>
            </Row>
        </Container>
    );
};

AboutArea.propTypes = {
    data: PropTypes.shape({
        section_title: PropTypes.shape(SectionTitleType),
        buttons: PropTypes.arrayOf(PropTypes.shape(ButtonType)),
        headings: PropTypes.arrayOf(PropTypes.shape(HeadingType)),
        texts: PropTypes.arrayOf(PropTypes.shape(TextType)),
    }),
};

export default AboutArea;
