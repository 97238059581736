import styled, { device } from "@styled";

export const BannerArea = styled.section`
    position: relative;
    isolation: isolate;
    padding-block-start: 70px;
    padding-block-end: 70px;

    ${device.large} {
        padding-block-start: 100px;
        padding-block-end: 100px;
    }
`;

export const StyledBG = styled.div`
    position: absolute;
    inset: 0;
    z-index: -1;
    .gatsby-image-wrapper {
        width: 100%;
        height: 100%;
        &:after {
            position: absolute;
            content: ".";
            display: block;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: linear-gradient(
                45deg,
                rgba(158, 181, 203, 1) 0%,
                rgba(255, 255, 255, 0.8) 40%,
                rgba(144, 144, 144, 0.8) 70%,
                rgba(19, 142, 160, 0.9) 100%
            );
        }
        img {
            -webkit-filter: saturate(0%) invert(80%);
            filter: saturate(0%) invert(80%);
        }
    }
`;

export const StyledBannerTextWrap = styled.div`
    text-align: center;
    max-width: 750px;
    margin-inline: auto;
`;

export const StyledTitle = styled.h1`
    color: #000;
    margin-block-end: 15px;
`;

export const StyledTagline = styled.h5`
    color: #000;
    font-weight: 400;
`;
