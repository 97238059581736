import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { normalizedData } from "@utils";
import Seo from "@components/seo";
import Layout from "@layout";
import Header from "@layout/header/layout-03";
import Footer from "@layout/footer/layout-01";
import PageHeader from "@containers/page-header/layout-01";
import ContentArea from "@containers/about/layout-04";
import Tier from "@containers/maintenance-support-plans";
import ContactArea from "@containers/contact/layout-01";
import Image from "@ui/image";
import { Container, Row, Col } from "@ui/wrapper";
import PricingChart from "../../data/images/maintenance-support-plans/pricing-chart.png";

const MaintenanceAndSupportPage = ({ location, data }) => {
    const content = normalizedData(data?.page.content || []);
    const globalContent = normalizedData(data?.allGeneral.nodes || []);

    return (
        <Layout location={location}>
            <Seo title="Maintenance and Support Plans" />
            <Header
                data={{
                    ...globalContent["header"],
                    ...globalContent["menu"],
                }}
            />
            <main className="site-wrapper-reveal">
                <PageHeader data={content["page-header-section"]} />
                <ContentArea
                    data={content["maintenance-support-1"]}
                    mt="mt-5"
                />
                <Container className="mb-5">
                    <Image src={PricingChart} alt="Softserv Pricing Plans" />
                </Container>
                <Tier data={content["tier-1"]} />
                <Tier data={content["tier-2"]} align="text-end" />
                <Tier data={content["tier-3"]} />
                <Tier data={content["tier-4"]} align="text-end" />
                <Tier data={content["tier-5"]} />
                <ContentArea data={content["extra-1"]} titleClass="h2" />
                <ContentArea data={content["extra-2"]} titleClass="h2" />
                <ContentArea data={content["extra-3"]} titleClass="h2" />
                <ContactArea
                    data={{
                        ...content["contact-section"],
                        contact: data.site.siteMetadata.contact,
                    }}
                />
            </main>
            <Footer data={{ ...data.site.siteMetadata }} />
        </Layout>
    );
};

export const query = graphql`
    query MaintenanceAndSupportPageQuery {
        allGeneral {
            nodes {
                section
                ...HeaderOne
            }
        }
        site {
            ...Site
        }
        page(
            title: { eq: "maintenance-support-plans" }
            pageType: { eq: "innerpage" }
        ) {
            content {
                ...PageContent
            }
        }
        allItSolution(limit: 3) {
            nodes {
                ...ItSolutionThree
            }
        }
    }
`;

MaintenanceAndSupportPage.propTypes = {
    location: PropTypes.shape({}),
    data: PropTypes.shape({
        allGeneral: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        site: PropTypes.shape({
            siteMetadata: PropTypes.shape({
                contact: PropTypes.shape({}),
            }),
        }),
        page: PropTypes.shape({
            content: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        allItSolution: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
    }),
};

export default MaintenanceAndSupportPage;
