import React from "react";
import PropTypes from "prop-types";
import Image from "@ui/image";
import Button from "@ui/button";
import { ImageType } from "@utils/types";
import {
    BoxIconWrap,
    BoxIconInner,
    BoxIconTop,
    IconWrap,
    Heading,
    BoxIconBottom,
    Text,
} from "./style";

const BoxIcon = ({ icon, title, desc, path, className }) => {
    return (
        <BoxIconWrap className={className}>
            <BoxIconInner>
                <BoxIconTop>
                    {icon?.src && (
                        <IconWrap>
                            <Image src={icon.src} alt={icon?.alt || title} />
                        </IconWrap>
                    )}
                    {title && <Heading>{title}</Heading>}
                </BoxIconTop>
                <BoxIconBottom>
                    {desc && <Text>{desc}</Text>}
                    {path && (
                            <Button
                                path={path}
                                variant="texted"
                                icondistance="8px"
                                fontWeight={400}
                                icon="far fa-long-arrow-right"
                            >
                                Discover now
                            </Button>
                    )}
                </BoxIconBottom>
            </BoxIconInner>
        </BoxIconWrap>
    );
};

BoxIcon.defaultProps = {
    title: "",
    desc: "",
};

BoxIcon.propTypes = {
    title: PropTypes.string,
    desc: PropTypes.string,
    icon: PropTypes.shape(ImageType),
    className: PropTypes.string,
};

export default BoxIcon;
